export const sortFavorites = (favorites) => {
  const sortedFavorites = [...favorites].sort((a, b) => {
    // if (!a.pharmacyId || !b.pharmacyId) {
    //   if (!a.pharmacyId && b.pharmacyId) return -1;
    //   if (a.pharmacyId && !b.pharmacyId) return 1;
    // } else if (a.pharmacyId !== b.pharmacyId) {
    //   return a.pharmacyId - b.pharmacyId;
    // }

    // if (!a.visitType || !b.visitType) {
    //   if (!a.visitType && b.visitType) return -1;
    //   if (a.visitType && !b.visitType) return 1;
    // } else if (a.visitType !== b.visitType) {
    //   return a.visitType.localeCompare(b.visitType);
    // }

    if (!a.favoriteName || !b.favoriteName) {
      if (!a.favoriteName && b.favoriteName) return -1;
      if (a.favoriteName && !b.favoriteName) return 1;
    }
    return a.favoriteName.localeCompare(b.favoriteName);
  });

  return sortedFavorites;
};

export const tableKeyTitles = {
  createUpdate: "",
  favoriteName: "Favorite Name",
  clientName: "Medicine Id",
  baskName: "Package NDC",
  medication: "Medication",
  type: "Type",
  strength: "Strength",
  sig: "Sig",
  quantity: "Quantity",
  dispenseUnit: "Dispense",
  refills: "Refills",
  daysSupply: "Days",
  category: "Category",
  pharmacyNotes: "Pharmacy Notes",
  visitType: "Visit Type",
  pharmacyId: "Pharmacy ID",
};

export const generateRandomClientName = (length = 32) => {
  const charset =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const res = Array.from(
    { length },
    () => charset[Math.floor(Math.random() * charset.length)]
  ).join("");

  return res;
};

export const isCompanyBask = (companyName) => {
  return /^bask/i.test(companyName);
};
